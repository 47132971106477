import { HeaderSizes, ThemeVariants } from 'rds'

// Local storage
export const REANGO_TOKEN_LOCALSTORAGE_NAME = 'reangoWebstoreToken'
export const REANGO_GUEST_TOKEN_LOCALSTORAGE_NAME = 'reangoWebstoreGuestToken'
export const REANGO_CART_LOCALSTORAGE_NAME = 'reangoWebstoreCart'

export const PRODUCT_RATING = ['', '', '', '', '']
export const REANGO_WEBSTORE_BUILDER_MESSAGES = Object.freeze({
    FETCH_WEBSTORE_SETTINGS: 'FETCH_WEBSTORE_SETTINGS',
    LOCATE_SETTING: 'LOCATE_SETTING',
    COMPONENT_PROPS_REQUEST: 'COMPONENT_PROPS_REQUEST',
    COMPONENT_PROPS_RESPONSE: 'COMPONENT_PROPS_RESPONSE',
    CURRENT_PAGE_REQUEST: 'CURRENT_PAGE_REQUEST',
    NAVIGATION: 'NAVIGATION',
    FETCH_PAGES: 'FETCH_PAGES',
    FETCH_PAGE: 'FETCH_PAGE',
})
export const ELEMENT_HIGHLIGHTED_CLASSNAME = 'webstore-builder_highlighted'
export const LAYOUT_TYPES = Object.freeze({
    ONE_COLUMN: 'ONE_COLUMN',
    LEFT_SIDEBAR: 'LEFT_SIDEBAR',
    RIGHT_SIDEBAR: 'RIGHT_SIDEBAR',
})
export const USER_ROLES = Object.freeze({
    WEBSTORE_GUEST: 'webstore_guest',
    WEBSTORE_USER: 'webstore_user',
})
export const PRODUCT_CARROUSEL_TYPES = Object.freeze({
    DEFAULT: 'DEFAULT',
    MOST_SOLD: 'MOST_SOLD',
    RECOMMENDED: 'RECOMMENDED',
    RELATED: 'RELATED',
})
export const HEADER_VARIANTS = Object.values(ThemeVariants).filter(
    variant =>
        variant === ThemeVariants.WHITE ||
        variant === ThemeVariants.DARK ||
        variant === ThemeVariants.MAIN ||
        variant === ThemeVariants.SECONDARY,
)
export const HEADER_SIZES = Object.values(HeaderSizes)
export const POSITIONING = Object.freeze({
    CENTER: 'center',
    TOP: 'top',
    LEFT: 'left',
    RIGHT: 'right',
    BOTTOM: 'bottom',
})
export const POSITIONING_ENUM = Object.values(POSITIONING)
export const COMPONENTS_CATEGORIES = Object.freeze({
    CATEGORY: {
        en: 'Categories',
        es: 'Categorías',
    },
    PRODUCT: {
        en: 'Products',
        es: 'Productos',
    },
    USER: {
        en: 'Users',
        es: 'Usuarios',
    },
    CASE: {
        en: 'Cases',
        es: 'Casos',
    },
    DESIGN: {
        en: 'Design',
        es: 'Diseño',
    },
    SHIPMENT_COSTS: {
        en: 'Shipment Costs',
        es: 'Costos de Envío',
    },
})
export const LOGO_DEFAULT_HEIGHT = 50
export const CITIES = Object.freeze([
    { label: 'Montevideo', id: 'Montevideo', value: 'Montevideo' },
    { label: 'Canelones', id: 'Canelones', value: 'Canelones' },
])
export const COUNTRIES = Object.freeze([{ label: 'Uruguay', id: 'Uruguay' }])
export const SALE_STATUSES = Object.freeze({
    DRAFT: 'DRAFT',
    NEW: 'NEW',
    CONFIRMED: 'CONFIRMED',
    READY: 'READY',
    PAYMENT_RECEIVED: 'PAYMENT_RECEIVED',
    IN_TRANSIT: 'IN_TRANSIT',
    DELIVERED: 'DELIVERED',
})
export const SALE_PAYMENT_TYPES = Object.freeze({
    ONSITE: 'ONSITE',
    ONLINE: 'ONLINE',
})
export const MERCADOPAGO_PAYMENT_STATUS = Object.freeze({
    REJECTED: 'rejected',
    CANCELLED: 'cancelled',
})
export const CLOUDINARY_EFFECTS = Object.freeze({
    NONE: 'NONE',
    FACE: 'FACE',
    ROUNDED: 'ROUNDED',
    BACKGROUND_COLOR_MAIN: 'BACKGROUND_COLOR_MAIN',
    BACKGROUND_COLOR_SECONDARY: 'BACKGROUND_COLOR_SECONDARY',
    BACKGROUND_COLOR_WHITE: 'BACKGROUND_COLOR_WHITE',
    BACKGROUND_COLOR_TRANSPARENT: 'BACKGROUND_COLOR_TRANSPARENT',
    GRAYSCALE: 'GRAYSCALE',
    NEGATIVE: 'NEGATIVE',
    BLUR: 'BLUR',
})
