import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {
    Block,
    Button,
    Card,
    FormItem,
    FormItemTypes,
    ThemeVariants,
    Typography,
    TypographyTypes,
} from 'rds'
import { getAddressGoogleMapsSearch } from '../../utils/address'
import api from '../../api'
import UserAddressItem from '../User/UserAddressItem'
import DeliverFormAddressSelectorAdd from './DeliverFormAddressSelectorAdd'
import { CITIES, COUNTRIES } from '../../constants'

const DEFAULT_ADDRESS_FORM = Object.freeze({
    city: CITIES[0].id,
    country: COUNTRIES[0].id,
})

const DeliverFormAddressSelector = ({ onChange }) => {
    const sessionUser = useSelector(state => state.session.user, shallowEqual)
    const [addresses, setAddresses] = React.useState()
    const [selectedAddress, setSelectedAddress] = React.useState()
    const [addressForm, setAddressForm] = React.useState(DEFAULT_ADDRESS_FORM)
    const [modal, setModal] = React.useState({
        visible: false,
        form: DEFAULT_ADDRESS_FORM,
    })
    const initialFetch = React.useRef()

    const sendPayloadToParent = React.useCallback(
        address => {
            if (typeof onChange === 'function') {
                Object.keys(address).forEach(key => {
                    onChange(key, address[key])
                })
            }
        },
        [onChange],
    )

    const handleAddressChange = (key, value) => {
        const updatedAddressForm = {
            ...addressForm,
            [key]: value,
        }
        setAddressForm(updatedAddressForm)
        sendPayloadToParent(updatedAddressForm)
    }

    const handleSelectAddress = React.useCallback(
        address => {
            setSelectedAddress(address)
            sendPayloadToParent(address)
        },
        [sendPayloadToParent],
    )

    const fetchAddresses = React.useCallback(
        async contactId => {
            const res = await api.get(`contacts/${contactId}/addresses`)
            const { contactAddresses } = res.data
            if (Array.isArray(contactAddresses) && contactAddresses.length) {
                setAddresses(contactAddresses)
                handleSelectAddress(contactAddresses[0])
            }
        },
        [handleSelectAddress],
    )

    React.useEffect(() => {
        if (!sessionUser || !sessionUser.contact) {
            return
        }
        if (!initialFetch.current) {
            initialFetch.current = true
            const contactId = sessionUser.contact._id || sessionUser.contact
            fetchAddresses(contactId)
        }
    }, [sessionUser, fetchAddresses])

    const handleAddAddress = async () => {
        setModal(modal => ({ ...modal, form: {}, visible: true }))
    }

    const handleAddressSubmit = async address => {
        const contactId = sessionUser.contact._id || sessionUser.contact
        if (address._id) {
            await api.put(
                `/contacts/${contactId}/addresses/${address._id}`,
                address,
            )
        } else {
            await api.post(`/contacts/${contactId}/addresses`, address)
        }
        fetchAddresses(contactId)
        setModal(modal => ({ ...modal, visible: false }))
    }

    return (
        <>
            {modal.visible ? (
                <DeliverFormAddressSelectorAdd
                    onSubmit={handleAddressSubmit}
                    onClose={() =>
                        setModal(modal => ({ ...modal, visible: false }))
                    }
                    address={modal.form}
                />
            ) : null}
            {sessionUser.contact ? (
                <div className='rds-full-block rds-m_top__md rds-grid_2-columns_item'>
                    <Typography type={TypographyTypes.P}>
                        Dirección de envío
                    </Typography>
                    {Array.isArray(addresses) ? (
                        <div>
                            {addresses.map(address => {
                                const isSelected =
                                    selectedAddress &&
                                    selectedAddress._id === address._id
                                return (
                                    <Card
                                        key={address._id}
                                        className='rds-m_top__sm'
                                        onClick={() =>
                                            handleSelectAddress(address)
                                        }
                                        variant={
                                            isSelected
                                                ? ThemeVariants.MAIN_LIGHT
                                                : undefined
                                        }
                                    >
                                        <UserAddressItem
                                            isSelected={isSelected}
                                            address={address}
                                            onUpdate={addressId =>
                                                setModal({
                                                    ...modal,
                                                    visible: true,
                                                    form: addresses.find(
                                                        a =>
                                                            a._id === addressId,
                                                    ),
                                                })
                                            }
                                        />
                                    </Card>
                                )
                            })}
                        </div>
                    ) : null}
                    <div
                        className={`rds-flexbox align-center rds-m_top__sm ${
                            Array.isArray(addresses)
                                ? 'justify-end'
                                : 'justify-start'
                        }`}
                    >
                        <Button
                            label='+ Agregar dirección'
                            onClick={handleAddAddress}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <FormItem
                        id='address-line1'
                        autoComplete='shipping address-line1'
                        label='Calle'
                        onChange={event => {
                            handleAddressChange('street', event.target.value)
                        }}
                        value={addressForm.street}
                        required
                    />
                    <FormItem
                        id='door-number'
                        autoComplete='shipping door-number'
                        label='Numero de puerta'
                        onChange={event => {
                            handleAddressChange(
                                'doorNumber',
                                event.target.value,
                            )
                        }}
                        value={addressForm.doorNumber}
                        required
                    />
                    <FormItem
                        id='address-line2'
                        autoComplete='shipping address-line2'
                        label='Apartamento (opcional)'
                        onChange={event => {
                            handleAddressChange('apartment', event.target.value)
                        }}
                        value={addressForm.apartment}
                    />
                    <FormItem
                        id='postal-code'
                        autoComplete='shipping postal-code'
                        label='Código postal (opcional)'
                        onChange={event => {
                            handleAddressChange('zipCode', event.target.value)
                        }}
                        value={addressForm.zipCode}
                    />
                    <FormItem
                        id='city'
                        autoComplete='shipping address-line3'
                        label='Ciudad'
                        onChange={event => {
                            handleAddressChange('city', event.target.value)
                        }}
                        value={addressForm.city}
                        type={FormItemTypes.SELECT}
                        values={CITIES}
                        forceDropdown
                        required
                    />
                    <FormItem
                        id='country-name'
                        autoComplete='shipping country-name'
                        label='País'
                        onChange={event => {
                            handleAddressChange('country', event.target.value)
                        }}
                        value={addressForm.country}
                        type={FormItemTypes.SELECT}
                        values={COUNTRIES}
                        forceDropdown
                        required
                    />
                    {process.env.REACT_APP_GOOGLE_API_KEY &&
                    addressForm.street &&
                    addressForm.doorNumber ? (
                        <Block
                            className='rds-full-block rds-m_top__md rds-overflow_hidden rds-grid_2-columns_item'
                            roundedBorder
                        >
                            <iframe
                                title='map'
                                className='rds-full-block'
                                src={`https://www.google.com/maps/embed/v1/search?key=${
                                    process.env.REACT_APP_GOOGLE_API_KEY
                                }&q=${getAddressGoogleMapsSearch(
                                    addressForm,
                                )}&zoom=17`}
                                style={{ minHeight: 250, border: 0 }}
                                allowFullScreen
                            />
                        </Block>
                    ) : null}
                </>
            )}
        </>
    )
}

export default DeliverFormAddressSelector
